<template>
  <!-- 服务首页 -->
  <div class="index">
    <section v-for="(menu, index) in menuList" :key="index">
      <h2>{{menu.name}}</h2>
      <div class="list">
        <div class="item" v-for="(item, i) in menu.sub" :key="i" @click="handleItem(item)">
          <i v-if="item.type === 'repair'" class="chame chame-apply"></i>
          <i v-if="item.type === 'registration'" class="chame chame-zhengjianbanli"></i>
          <i v-if="item.type === 'building_rubble'" class="chame chame-fangkepingzhengjinchu"></i>
          <i v-if="item.type === 'parking'" class="chame chame-tingcheyouhui"></i>
          <i v-if="item.type === 'verification'" class="chame chame-qiajuan"></i>
          <i v-if="item.type === 'question'" class="chame chame-tiaochabiaodan"></i>
          <i v-if="item.type === 'daily'" class="chame chame-yingyee"></i>
          <i v-if="item.type === 'staff'" class="chame chame-yuangongdengji-"></i>
          <i v-if="item.type === 'business'" class="chame chame-jingying"></i>
          <span class="font-28">{{item.name}}</span>
        </div>
      </div>
    </section>

    <div class="pwd-box">
      <van-button type="danger" block round @click="changePwd">更改密码</van-button>
    </div>

    <div class="logout-box">
      您好！{{sh_name}}
      <div class="logout-btn" @click="logout">
        <i class="chame chame-Logout"></i>
        退出
      </div>
    </div>

    <!-- 建垃二维码 -->
    <van-popup v-model:show="isShow">
      <div id="qrcode"></div>
    </van-popup>

    <!-- 证照办理分类 -->
    <van-action-sheet v-model:show="isShowActions" :actions="actions" @select="onSelect" />
  </div>
</template>

<script>
import { getUrlParams } from '@/utils'
import { getShHomeApi, getMerchantInfoApi } from '@/api'
import QRCode from 'qrcodejs2'
export default {
  name: 'Home',
  data() {
    return {
      sh_name: '', // 商户名
      isShow: false,
      menuList: [],
      isShowActions: false, // 显示证照办理分类
      actions: [
        { name: '施工证申请', path: '/license/build' },
        { name: '吊车申请', path: '/license/crane' },
        { name: '动火证申请', path: '/license/fire' },
        { name: '物品进货申请', path: '/license/in/goods' },
        { name: '物品出货申请', path: '/license/out/goods' },
      ],
      sid: '',
      encrypt: '',
    }
  },
  created() {
    const sh_info = JSON.parse(window.localStorage.getItem('sh_info')) || {}
    if (!sh_info.sid || !sh_info.encrypt) {
      this.sid = getUrlParams('sid')
      this.encrypt = getUrlParams('encrypt')
      const data = {
        sid: this.sid,
        encrypt: this.encrypt,
      }
      window.localStorage.setItem('sh_info', JSON.stringify(data))
      window.localStorage.removeItem('user_info')
    } else {
      this.sid = sh_info.sid
      this.encrypt = sh_info.encrypt
    }
    if (!this.sid || !this.encrypt){
      // return window.location.href = 'http://localhost:3000/#/login'
      return window.location.href = 'http://turnover.smart-business.xfsoftware.com.cn/#/login'
    }

    // 获取商户首页数据
    this.getShHomeData()
    // 获取商户信息
    this.getMerchantInfoData()
  },
  methods: {
    getShHomeData() {
      getShHomeApi({
        sid: this.sid,
        encrypt: this.encrypt,
      }).then((res) => {
        if (res.code !== 0) {
          window.localStorage.removeItem('sh_info')
        }
        this.menuList = res.data
      })
    },
    getMerchantInfoData() {
      getMerchantInfoApi({
        sid: this.sid,
      }).then(res => {
        console.log('商户信息：', res)
        this.sh_name = res.data.sh_name
      })
    },
    handleItem(item) {
      if (!item.enable){
        return this.$toast(item.message);
      }
      console.log('item',item);
      // 证照办理
      if (item.type === 'registration')
        return this.isShowActions = true
      // 建垃凭证
      if (item.type === 'building_rubble') {
        this.isShow = true
        if (this.qrcode) return
        this.$nextTick(() => {
          this.qrcode = new QRCode(document.getElementById('qrcode'), {
            text: window.location.origin + '/license/verify?sid=' + this.sid, // 需要转换为二维码的内容
            width: 150,
            height: 150,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
      }
      // 维修申请
      if (item.enable === '1') {
        if (item.type === 'building_rubble') return
        window.location.href = item.link
      } else {
        this.$toast(item.message)
      }
    },
    // 证照办理
    onSelect(event) {
      this.isShowActions = false
      this.$router.push(event.path)
    },
    // 退出登录
    logout() {
      this.$dialog({
        title: '您确认要退出登录吗？',
        showCancelButton: true
      }).then(() => {
        window.localStorage.clear();
        // window.location.href = 'http://localhost:3000/#/logout'
        window.location.href = 'https://889.turnover.chamshare.cn/#/logout'
      }).catch(() => {})
    },

    //  更改密码
    changePwd() {
      this.$router.push('/pwd')
    },
  },
}
</script>

<style lang="scss" scoped>
#qrcode {
  padding: 20px;
}
.index {
  padding: 0 40px;
  h2 {
    margin-top: 80px;
  }
  .list {
    margin-top: 20px;
    display: flex;
    .item {
      flex: 1;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #1989fa;
      color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      .chame {
        font-size: 36px;
      }
      &:nth-of-type(2) {
        margin: 0 20px;
      }
      &:active {
        opacity: 0.8;
      }
    }
  }

  .pwd-box {
    padding: 80px;
  }
}
</style>
